import React from 'react';
import Grid from '@mui/material/Grid';
import { GatsbyImage } from 'gatsby-plugin-image';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { noSelect } from '../../../styles';

const StyledGrid = styled(Grid)({
  boxShadow: '0px 0px 60px -20px rgba(0,0,0,0.75)',
  marginTop: '2rem',
  marginBottom: '1.5rem',
  borderRadius: 1,
  '&>div': {
    height: '40vh',
  },
});

const StyledTag = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.accent,
}));

const Hero = ({ img, title }) => {
  return (
    <>
      <StyledGrid item xs={12}>
        <GatsbyImage
          style={{ width: '100%' }}
          image={img}
          alt={img.description || img.title || ''}
        />
      </StyledGrid>
      <Grid item xs={12} mt={5} mb={2} sx={noSelect}>
        <Typography align="center" variant="h3">
          <StyledTag component="span" variant="h3">
            {'< '}
          </StyledTag>
          {title}
          <StyledTag component="span" variant="h3">
            {' />'}
          </StyledTag>
        </Typography>
      </Grid>
    </>
  );
};

export default Hero;
