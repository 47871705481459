import React from 'react';
import { graphql } from 'gatsby';
import ApplicationContainer from '../components/application-container';

import Squiggly from '../components/squiggly';
import Hero from '../components/BlogPost/hero';
import ContentSection from '../components/content-section';
import PostContent from '../components/BlogPost/post-content';
import Hyvor from '../components/BlogPost/hyvor';
import { getInnerTextFrom } from '../util/formatter';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { SEO as Seo } from '../components/seo';

export const Head = ({ data }) => {
  const post = data.contentfulBlogPost;
  const siteTitle = data.site.siteMetadata.title;
  const postDescription = getInnerTextFrom(
    post.description.childMarkdownRemark.html,
  );
  const heroImage = getImage(post.heroImage);
  const metaImage = getSrc(heroImage);
  return (
    <Seo
      title={`${post.title} | ${siteTitle}`}
      description={postDescription}
      blogPost={{
        keywords: post.tags,
        image: metaImage,
      }}
    />
  );
};

const BlogPostTemplate = ({ data }) => {
  const post = data.contentfulBlogPost;
  const heroImage = getImage(post.heroImage);

  return (
    <>
      <ApplicationContainer>
        <Hero img={heroImage} title={post.title} />
      </ApplicationContainer>

      <ApplicationContainer>
        <ContentSection>
          <PostContent
            body={post.body}
            author={post.author}
            date={post.publishDate}
          />
        </ContentSection>
      </ApplicationContainer>

      <ApplicationContainer>
        <Squiggly />
      </ApplicationContainer>

      <ApplicationContainer>
        <Hyvor slug={post.slug} />
      </ApplicationContainer>
    </>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      author {
        name
      }
      tags
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        width
        height
        gatsbyImage(layout: FIXED, width: 1200, height: 630)
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        description
        title
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      slug
    }
  }
`;
