import React, { useLayoutEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { StyledTypography } from './styles.js';
import Prism from 'prismjs';
import './prism.css';

const PostContent = ({ body, author, date }) => {
  useLayoutEffect(() => {
    const pre = document.getElementsByTagName('pre');
    !!pre.length && [...pre].forEach((tag) => Prism.highlightAllUnder(tag));
  }, []);

  return (
    <>
      <Grid item xs={12} md={9} xl={7}>
        <StyledTypography
          dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}
          component="div"
        />

        <Typography
          align="center"
          sx={{
            marginTop: '4em',
            fontStyle: 'italic',
          }}
          variant="body2"
        >
          Posted by {author.name} - {date}
        </Typography>
      </Grid>
    </>
  );
};

export default PostContent;
