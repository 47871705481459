import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { terminalHeader } from '../../../styles';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  '&>h1,h2,h3,h4,h6': {
    fontFamily: 'JetBrains Mono',
    fontWeight: 400,
  },
  '&>p': {
    fontFamily: theme.typography.p,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(3),
    '&>strong': {
      fontSize: '1rem',
    },
    '& > del': {
      fontStyle: 'italic',
    },
  },
  '&>hr': {
    borderColor: theme.palette.warning.light,
    border: 0,
    height: '1px',
    backgroundColor: theme.palette.warning.dark,
  },
  '&>blockquote': {
    fontStyle: 'italic',
    fontSize: '0.8rem',
    padding: '0.8rem',
    paddingLeft: '1.2rem',
    margin: 0,
    borderLeft: `6px solid ${theme.palette.warning.dark}`,
    backgroundColor: theme.palette.background.paper,
    '&::before': {
      marginRight: '10px',
      position: 'relative',
      transform: 'translateY(50%)',
    },
  },
  '&>p>a': {
    textDecoration: 'none',
    color: theme.palette.success.light,
  },
  '&>p>a[href*="gist"]': {
    marginLeft: '45%',
  },
  '&>ul>li>a': {
    textDecoration: 'none',
    color: theme.palette.success.light,
  },
  '&>ul>li': {
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    marginTop: '1em',
    listStyle: 'none',
    '&::before': {
      content: '">"',
      position: 'absolute',
      transform: 'translateX(-200%)',
      color: theme.palette.secondary.accent,
    },
  },
  '&>p>img': {
    width: '50%',
    marginLeft: '25%',
    padding: '1rem',
    boxShadow: '0px 0px 16px -5px rgba(0,0,0,0.75)',
    borderRadius: '10px',
  },
  '&>pre': {
    paddingTop: '2rem !important',
    '::before': {
      position: 'relative',
      top: '-1.5rem',
      left: '-0.25rem',
      ...terminalHeader,
    },
  },
  '& * > code': {
    // inline code blocks
    borderRadius: '5px',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    color: theme.palette.secondary.accent,
    backgroundColor: theme.palette.background.code,
  },
  '& > pre::-webkit-scrollbar': {
    backgroundColor: theme.palette.primary.code,
    borderRadius: '20px',
    width: '10px',
    height: '10px',
  },
  '& > pre::-webkit-scrollbar-track': {
    borderRadius: '10px',
    marginRight: '25px',
  },
  '& > pre::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
  },
}));
