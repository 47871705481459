import React from 'react';
import { Embed } from 'hyvor-talk-react';
import ContentSection from '../../content-section';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

const Hyvor = ({ slug }) => {
  const theme = useTheme();
  return (
    <ContentSection>
      <Grid item xs={12}>
        <Embed
          palette={{
            accent: theme.palette.secondary.accent,
            accentText: theme.palette.text.secondary,
            footerHeader: theme.palette.background.default,
            footerHeaderText: theme.palette.text.primary,
            box: theme.palette.background.main,
            boxText: theme.palette.text.primary,
            boxLightText: theme.palette.primary.contrastText,
            backgroundText: theme.palette.text.primary,
          }}
          websiteId={2025}
          id={slug}
        />
      </Grid>
    </ContentSection>
  );
};

export default Hyvor;
